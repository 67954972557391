<template>
  <v-app>
    <v-navigation-drawer
      :temporary="isMobile()"
      :expand-on-hover="!isMobile()"
      v-model="drawer"
      clipped
      app
    >
      <v-list nav dense v-for="(item, idx) in menuItems" :key="idx">
        <v-list-item :to="'/' + item.url" link>
          <v-list-item-icon>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" dark app clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <img width="140" src="@/assets/Dimitra_logo_white.png" class="mr-12 " />

      <v-spacer></v-spacer>

      <template v-if="!isMobile()">
        <v-btn class="ml-4" icon dark>
          <v-icon>mdi-poll</v-icon>
        </v-btn>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-badge dot color="error" overlap>
                  <v-icon>mdi-bell</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <v-list subheader three-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Example Notification 1</v-list-item-title>
                  <v-list-item-subtitle>Subtext here</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
            <v-btn block>clear all notifications</v-btn>
          </v-menu>
        </div>

        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon dark v-bind="attrs" v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>Edit Account</v-list-item>
              <v-list-item>Changelog</v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                Log Out
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-else></template>
    </v-app-bar>

    <v-main class="themeGrey ">
      <v-container>
        <router-view></router-view>
      </v-container>
      <template v-if="isMobile()">
        <v-bottom-navigation app dark background-color="primary" fixed>
          <v-btn v-for="(item, idx) in navItems" :key="idx">
            <span>{{ item.name }}</span>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </template>
      <template v-else></template>
    </v-main>
  </v-app>
</template>

<script>
import isMobileMixin from "@/mixins/isMobileMixin";
export default {
  data: () => ({
    drawer: null,
    menuItems: [
      { icon: "home", name: "Home" },
      { icon: "barn", name: "My Farm", url: "my-farm" },
      { icon: "barley", name: "My Crops", url: "crops" },
      { icon: "cow", name: "My Livestock", url: "livestock" },
      { icon: "store", name: "Marketplace", url: "marketplace" },
      { icon: "chart-bar", name: "Finance" },
      { icon: "file-document", name: "My Documents", url: "my-documents" },
      { icon: "card-text-outline", name: "Service Desk" },
      {
        icon: "information",
        name: "Knowledge Garden",
        url: "knowledge-garden",
      },
      {
        name: "Government Service Desk",
        url: "government-service-desk",
      },
      {
        name: "My Reports",
        url: "my-reports",
      },
    ],
    navItems: [
      { icon: "cog", name: "Settings" },
      { icon: "poll", name: "My Reports" },
      { icon: "bell", name: "Notifications" },
    ],
  }),
  mixins: [isMobileMixin],
};
</script>

<style lang="scss">
// font
* {
  font-family: "Poppins", sans-serif;
}
</style>
