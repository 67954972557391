import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/dashboard/Dashboard.vue";

Vue.use(VueRouter);

const routes = [
  // dashboard
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },

  // my farm
  {
    path: "/my-farm",
    name: "my farm",
    component: () => import("../views/my_farm/Index.vue"),
  },
  {
    path: "/my-farm/farm-registration",
    name: "farm registration",
    component: () => import("../views/my_farm/FarmRegistration.vue"),
  },
  {
    path: "/my-farm/my-documents",
    name: "my documents",
    component: () => import("../views/my_farm/MyDocuments.vue"),
  },

  // crops
  {
    path: "/crops",
    name: "crops",
    component: () => import("../views/crops/Index.vue"),
  },
  {
    path: "/planting",
    name: "planting",
    component: () => import("../views/crops/Planting.vue"),
  },

  // livestock
  {
    path: "/livestock",
    name: "livestock",
    component: () => import("../views/livestock/Index.vue"),
  },
  {
    path: "/livestock-observations",
    name: "livestock observations",
    component: () => import("../views/livestock/LiveStockObservations.vue"),
  },
  {
    path: "/register-edit-livestock",
    name: "register edit livestock",
    component: () => import("../views/livestock/RegisterEditLivestock.vue"),
  },
  {
    path: "/register-new-livestock",
    name: "register new livestock",
    component: () => import("../views/livestock/RegisterNewLivestock.vue"),
  },
  {
    path: "/sell-trade",
    name: "sell trade",
    component: () => import("../views/livestock/SellTrade.vue"),
  },
  {
    path: "/buy-view",
    name: "buy view",
    component: () => import("../views/livestock/BuyView.vue"),
  },
  {
    path: "/livestock/my-documents",
    name: "my documents",
    component: () => import("../views/livestock/MyDocuments.vue"),
  },
  {
    path: "/send-to-abattoir",
    name: "send to abattoir",
    component: () => import("../views/livestock/SendToAbattoir.vue"),
  },
  {
    path: "/set-up-sensors",
    name: "set up sensors",
    component: () => import("../views/livestock/SetUpSensors.vue"),
  },
  {
    path: "/ship-and-track",
    name: "ship and track",
    component: () => import("../views/livestock/ShipAndTrack.vue"),
  },
  {
    path: "/shipment-details",
    name: "shipment details",
    component: () => import("../views/livestock/ShipmentDetails.vue"),
  },

  // marketplace
  {
    path: "/marketplace",
    name: "marketplace",
    component: () => import("../views/marketplace/Index.vue"),
  },

  // my documents
  {
    path: "/my-documents",
    name: "my documents",
    component: () => import("../views/my_documents/Index.vue"),
  },
  {
    path: "/my-documents/add-document",
    name: "add document",
    component: () => import("../views/my_documents/AddDocument.vue"),
  },

  // government service desk
  {
    path: "/government-service-desk",
    name: "government service desk",
    component: () => import("../views/government_service_desk/Index.vue"),
  },

  // my reports
  {
    path: "/my-reports",
    name: "my reports",
    component: () => import("../views/my_reports/Index.vue"),
  },
  {
    path: "/my-reports/satellite-reports",
    name: "satellite reports",
    component: () => import("../views/my_reports/SatelliteReports.vue"),
  },
  {
    path: "/my-reports/weather-reports",
    name: "weather reports",
    component: () => import("../views/my_reports/WeatherReports.vue"),
  },

  // knowledge garden
  {
    path: "/knowledge-garden",
    name: "knowledge garden",
    component: () => import("../views/knowledge_garden/Index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
