import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
// import * as firebase from "firebase";

Vue.use(VueApexCharts);

// const configOptions = {
//   apiKey: "AIzaSyC33tg6hg4PYHVP0Kr0lI--ppOpzSU0A0E",
//   authDomain: "dimitra-82a9a.firebaseapp.com",
//   databaseURL: "https://dimitra-82a9a-default-rtdb.firebaseio.com",
//   projectId: "dimitra-82a9a",
//   storageBucket: "dimitra-82a9a.appspot.com",
//   messagingSenderId: "464930559018",
//   appId: "1:464930559018:web:53bc5a447db7240c329f53",
//   measurementId: "G-QJN0PS6P30",
// };

// firebase.initializeApp(configOptions);

Vue.component("apexchart", VueApexCharts);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
